.header {
    background: var(--lightblue);
    color: var(--lightbluedark);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.image {
    margin-right: 96px;
    width: 33%;
    max-width: 500px;
}

.image img {
    width: 100%;
}

.content {
    width: 45%;
    max-width: 600px;
}

h1 {
    font-size: 2rem;
}

p {
    font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
    .header {
        flex-direction: column;
        height: auto;
    }

    .image {
        margin-right: 0;
        width: 80vw;
        padding-top: 32px;
    }

    .content {
        padding: 2rem;
        width: 100%;
    }
}
