form {
    margin-top: 96px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    margin: 2rem auto;
    max-width: 800px;
}

.filler {
    flex: 2;
}

.label {
    flex: 2;
    margin-right: 2rem;
}

.radio {
    cursor: pointer;
    font-weight: bold;
    margin: 0 2rem;
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    user-select: none;
}

.radio__input {
    display: flex;
}

.radio__input input {
    opacity: 0;
    width: 0;
    height: 0;
}

.radio__input input + .radio__control::before {
    content: "";
    width: .5em;
    height: .5em;
    box-shadow: inset .5em .5em currentColor;
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
}

.radio__input input:checked + .radio__control::before {
    transform: scale(1);
}

.radio__input input:focus + .radio__control {
    box-shadow: 0 0 0 0.1em #fff, 0 0 0.25em 0.1em currentColor;
}

.radio__label {
    line-height: 1;
    font-size: 1.2rem;
    font-weight: normal;
}

.radio__control {
    display: grid;
    place-items: center;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid currentColor;
    transform: translateY(-0.1em);
}

.inputlabel {
    font-size: 1.2rem;
    line-height: 1;
}

.numberinput {
    border: 1px solid var(--lightbluedark);
    color: var(--lightbluedark);
    font-size: 20px;
    font-weight: bold;
    padding: 0.5em;
    margin: 1em;
    width: 12rem;
}

.numberinput:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--lightblue);
    outline: none;
}

.button {
    background: var(--lightbluedark);
    border: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    padding: 0.9em;
    margin: 1em;
    text-transform: uppercase;
    white-space: nowrap;
}

.button:disabled {
    opacity: 0.5;
}

.button:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--lightblue);
    outline: 1px solid var(--lightbluedark);
}

@media screen and (max-width: 900px) {
    form {
        margin-top: 0;
    }

    .row {
        flex-direction: column;
        margin: 2rem;
    }

    .label {
        margin-bottom: 1rem;
    }

    .radio {
        width: 12rem;
    }

    .radio__label {
        grid-column: 2;
        grid-row: 1;
        padding: 0.7rem;
    }

    .radio__input {
        grid-column: 1;
        grid-row: 1;
        align-items: center;
    }

    .inputlabel {
        text-align: center;
    }

    .row.share {
        width: 100px;
        flex-direction: row;
        align-items: center;
        margin: -2rem auto auto;
    }
}
