.cookiebtn {
    background: var(--lightbluedark);
    border: 2px solid var(--lightbluedark);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    padding: 0.9em;
    margin: 1em;
    text-transform: uppercase;
    white-space: nowrap;
}
button#rcc-decline-button {
    background: transparent;
    border: 2px solid;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    padding: 0.9em;
    margin: 1em;
    text-transform: uppercase;
    white-space: nowrap;
}
.cookiebtn:disabled {
    opacity: 0.5;
}

.cookiebtn:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--lightblue);
    outline: 1px solid var(--lightbluedark);
}

.cookiecontainer {
    align-items: center;
    padding: 1em;
}

.cookiecontent h6 {
    font-size: 1.1rem;
    margin: 0 0 1rem;
}
.cookiecontent p {
    font-size: 0.9rem;
    margin: 0;
}

.row.share {
    margin-top: -2rem;
    width: 100px;
}
