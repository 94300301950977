.result {
    max-width: 800px;
    margin: 96px auto 0;
}

.result h2 {
    text-align: center;
}

.result-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.result-value {
    margin-top:2rem;
    font-size: 5em;
    white-space: nowrap;
    font-weight: 700;;
}

.result-footer {
    margin-top: 64px;
}

.result-footer p {
    font-size: 0.8rem;
}

@media screen and (max-width: 900px) {
    .result {
        margin: 0;
        padding: 1rem;
    }

}

@media screen and (max-width: 600px) {

    .result-value {
        font-size: 3em;
    }
}
