::placeholder {
    color: var(--lightblue);
    font-weight: 200;
}

form.subscribe {
    margin: 2em 0;
    background: var(--lightblue);
    padding: 30px;
}

.subrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 2rem auto;
    max-width: 800px;
}

.textinput {
    border: 1px solid var(--lightbluedark);
    color: var(--lightbluedark);
    font-size: 20px;
    font-weight: bold;
    padding: 0.5em;
    margin-right: 1em;
}

.textinput:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--lightblue);
    outline: none;
}

.submitbtn {
    background: var(--lightbluedark);
    border: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    padding: 0.9em;
    margin: 1em 0;
    text-transform: uppercase;
    white-space: nowrap;
}

.submitbtn:disabled {
    opacity: 0.5;
}

.submitbtn:focus {
    box-shadow: 0 0 0 0.15em #fff, 0 0 0.35em 0.15em var(--lightblue);
    outline: 1px solid var(--lightbluedark);
}

.error {
    color: #a00;
    font-size: 1rem;
}

.success {
    color: #0a0;
    font-size: 1rem;
}

small {
    font-size: 0.8rem;
    font-weight: normal;
}

a {
    color: var(--lightbluedark);
}

@media screen and (max-width: 768px) {
    .subrow {
        flex-direction: column;
    }

    .textinput {
        margin-bottom: 1em;
    }

    button.submitbtn {
        width: 100%;
    }

    input.textinput {
        margin-right: 0;
        width: 100%;
    }
}
