.footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 64px;
    flex-direction: column;
}
.menu {
    margin-bottom: 20px;
}

.menu ul {
    display: flex;
    list-style: none;
    margin:0;
    padding:0;
}

.menu li {
    padding-right:10px;
    text-decoration: underline;
}

img {
    width: 64px;
}

@media screen and (max-width: 900px) {
    .footer {
        flex-direction: column;
        height: auto;
    }

    .menu ul {
        flex-direction: column;
        text-align: center;
    }

    .menu ul li {
        margin: 0.3rem 0;
        padding: 0;
    }
}
