.about {
    max-width: 800px;
    margin: 96px auto 0;
    padding: 30px;
}

a {
    color: var(--lightbluedark);
    font-weight: bold;
}
